function InvalidIVCheck(client) {
  var nutritionRequest = client.request("NutritionOrder?patient=" + client.patient.id, { pageLimit: 0 });
  var medicationRequest = client.request("MedicationRequest?patient=" + client.patient.id, { pageLimit: 0 });
  var serviceRequest = client.request("ServiceRequest?patient=" + client.patient.id, { pageLimit: 0 });
  var mainPromise = Promise.all([nutritionRequest, medicationRequest, serviceRequest]).then((results) => {
    console.log(JSON.stringify(results));
  })
  return mainPromise;
}

export default InvalidIVCheck;