export default function UnitService(){
    var api = 'https://jbh-fhir.azurewebsites.net/api/HospitalUnits';

//    var api = 'https://localhost:44304/api/HospitalUnits';

    this.getUnits = () => {
        return fetch(api, {
            
          })
    }

    this.getUnit = (id) => {
        return fetch(api + "/" + id, {
            "method": "GET",
            "headers": {
              "content-type": "application/json",
              "accept": "application/json"
            }
          })
    }

    this.updateUnit = (unit) => {
       return fetch(api + '/' + unit.id, {
            "method": "PUT",
           
            "headers": {
              "content-type": "application/json",
              "accept": "application/json"
            },
            "body": JSON.stringify(unit)
          })
    }

    this.createUnit = (unit) => {
        return fetch(api, {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json"
              },
            "body": JSON.stringify(unit)
          })
    }

    this.getPatients = (unit) => {
      return [
        {
          "label" : "Terri Johnson",
          "id" : "abc123",
        },{
          "label" : "Zach O'Brien",
          "id" : "abc133",
        },{
          "label" : "Daryl Jennings",
          "id" : "abc143",
        },{
          "label" : "Amanda Fowler",
          "id" : "abc153",
        }
      ]
    }

    this.getScanResults = (patient) => {
      return [
        {
          "title": "Patient has Potassium readings outside of guidelines in the last 48 hours.",
          "level" : "Warn",
          "category": "Resource Utilization"
        },
        {
          "title": "48 hour bacterial culture testing not scheduled.",
          "level" : "Warn",
          "category": "Resource Utilization"
        },
        {
          "title": "Patient has pre-existing discharge to hospice.",
          "level" : "Warn",
          "category": "Status"
        }
        ,
        {
          "title": "Resource optimization opportunity detected.",
          "level" : "Warn",
          "category": "Length of Stay"
        },
        
        {
          "title": "Potential for medication de-escalation.",
          "level" : "Warn",
          "category": "Length of Stay"
        },
        
        {
          "title": "Patient has been on anti-drinking medication in past 4 months.",
          "level" : "Warn",
          "category": "Status"
        }
        ,
        
        {
          "title": "Medication of interest - Clonodine",
          "level" : "Warn",
          "category": "Length of Stay"
        }
      ]
    }
}