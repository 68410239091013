export default function UserService(){
    var api = 'https://jbh-fhir.azurewebsites.net/api/CaseManagers';
    //var api = 'https://localhost:44304/api/CaseManagers';

    this.getUsers = (unitId) => {
        return fetch(api + "?unitid=" +unitId, {
            
          })
    }

    this.getUser = (id) => {
        return fetch(api + "?unitId=" + id, {
            "method": "POST",
            "headers": {
              "content-type": "application/json",
              "accept": "application/json"
            },
            "body": JSON.stringify({
              name: this.state.name,
              notes: this.state.notes
            })
          })
    }

    this.updateUser = (user) => {
       return fetch(api, {
            "method": "PUT",
           
            "body": JSON.stringify({
              name: this.state.name,
              notes: this.state.notes
            })
          })
    }

    this.createUser = (user, unitId) => {
        var req = {
            user,
            unitId
        }
        return fetch(api, {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json"
              },
            "body": JSON.stringify(req)
          })
    }
}