

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import UnitService from '../Services/hospital-unit-service';

import { IconButton } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',

  color: theme.palette.text.secondary,
}));

function handleListItemClick(event,id) {
  window.location.href = "/unit-details?unit=" + id
}


function UnitPage() {
  const [units, setUnits] = React.useState([]);
  React.useEffect(() => {
    if (!units.length) {
      const unitService = new UnitService();
      unitService.getUnits().then((u) => {
        u.json().then((d) => {
          setUnits(d);
        });
      });
    }
  })

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={2}></Grid>
        <Grid item elevation={3} xs={8}>
          <Item>
            <Stack direction="row" alignItems="center" justifyContent="center">
              <Item sx={{ boxShadow: 0, borderRadius: 0 }}><h1>UNITS</h1></Item>
              <Item sx={{ boxShadow: 0, borderRadius: 0 }}>
                <IconButton
                  onClick={(event) => handleListItemClick(event, 0)}>
                  <AddIcon />
                </IconButton>
              </Item>
            </Stack>
            <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
              <List component="nav" aria-label="main mailbox folders">
                
                {units && units.map ? units.map((unit, index) => (

                  <ListItemButton
                    onClick={(event) => handleListItemClick(event, unit.id)}
                  >
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary={unit.name} />
                  </ListItemButton>

                )) : null}
              </List>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </div>
  );
}

export default UnitPage