// src/HospitalMap.js
import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import Papa from 'papaparse';
import proj4 from 'proj4';
// Fix the default icon issue in Leaflet
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({  iconRetinaUrl: 'https://www.iconpacks.net/icons/3/free-hospital-location-pin-icon-10718-thumb.png',
    iconUrl: 'https://www.iconpacks.net/icons/3/free-hospital-location-pin-icon-10718-thumb.png',
});

// Define the projection transformation from EPSG:3857 to EPSG:4326 with WGS84 datum
proj4.defs([
  [
    'EPSG:3857',
    '+proj=merc +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs',
  ],
  [
    'EPSG:4326',
    '+proj=longlat +datum=WGS84 +no_defs',
  ],
]);

const services = [
  { name: 'Cardiac Cath Lab', key: 'CardiacCathLab' },
  { name: 'Carotid Stenting', key: 'CarotidStenting' },
  { name: 'Coronary Interventions', key: 'CoronaryInterventions' },
  { name: 'Vascular Intervention', key: 'VascularIntervention' },
  { name: 'Vascular Surgery', key: 'VascularSurgery' },
  { name: 'Emergency Department', key: 'EmergencyDepartment' },
  { name: 'Electroencephalography (EEG)', key: 'EEG' },
  { name: 'Sleep Studies', key: 'SleepStudies' },
  { name: 'Chemotherapy', key: 'Chemotherapy' },
  { name: 'Joint Replacement', key: 'JointReplacement' },
  { name: 'Spine Surgery', key: 'SpineSurgery' },
  { name: 'Hemodialysis', key: 'Hemodialysis' },
  { name: 'Computed Tomography (CT)', key: 'CT' },
  { name: 'Computed Tomography-Angiography (CTA)', key: 'CTA' },
  { name: 'Magnetic Resonance Imaging (MRI)', key: 'MRI' },
  { name: 'Single Photon Emission Computerized Tomography (SPECT)', key: 'SPECT' },
  { name: 'Physical Therapy', key: 'PhysicalTherapy' },
  { name: 'Intensive Care Unit (ICU)', key: 'ICU' },
  { name: 'Psychiatric', key: 'Psychiatric' },
  { name: 'Rehabilitation', key: 'Rehabilitation' },
  { name: 'Inpatient Surgery', key: 'InpatientSurgery' },
  { name: 'Radiosurgery', key: 'Radiosurgery' },
  { name: 'Wound Care', key: 'WoundCare' },
];

const HospitalMap = () => {
  const [hospitals, setHospitals] = useState([]);
  const [filteredHospitals, setFilteredHospitals] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);

  useEffect(() => {
    // Load the CSV file
    fetch('/hospitals.csv')
      .then(response => response.text())
      .then(csvText => {
        Papa.parse(csvText, {
          header: true,
          complete: (results) => {
            const data = results.data.map(hospital => {
                if(hospital.x && hospital.y){
              const originalX = parseFloat(hospital.x);
              const originalY = parseFloat(hospital.y);
              const [lon, lat] = proj4('EPSG:3857', 'EPSG:4326', [originalX, originalY]);
              return {
                id: hospital.OBJECTID,
                name: hospital.NAME,
                address: `${hospital.ADDRESS}, ${hospital.CITY}, WA ${hospital.ZIP}`,
                link: hospital.Weblink,
                lat,
                lng: lon,
                services: hospital // Assuming hospital object contains service data
              };
            }else{
                return {};
            }
            });
            const filtData = data.filter(x => x.id);
            
            console.log(JSON.stringify(filtData));
            setHospitals(filtData);
            setFilteredHospitals(filtData);
          }
        });
      });
  }, []);

  const handleServiceChange = (serviceKey) => {
    setSelectedServices(prevState =>
      prevState.includes(serviceKey)
        ? prevState.filter(key => key !== serviceKey)
        : [...prevState, serviceKey]
    );
  };

  useEffect(() => {
    if (selectedServices.length === 0) {
      setFilteredHospitals(hospitals);
    } else {
      setFilteredHospitals(hospitals.filter(hospital =>
        selectedServices.every(service => hospital.services[service] === 'Yes')
      ));
    }
  }, [selectedServices, hospitals]);

  return (
    <div className="map-container">
      <div className="sidebar">
        <h3>Filter by Services</h3>
        {services.map(service => (
          <div key={service.key}>
            <input
              type="checkbox"
              id={service.key}
              value={service.key}
              onChange={() => handleServiceChange(service.key)}
            />
            <label htmlFor={service.key}>{service.name}</label>
          </div>
        ))}
      </div>
      <MapContainer center={[47.751076, -120.740135]} zoom={7} style={{ height: '100vh', width: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {filteredHospitals.map(hospital => (
          <Marker key={hospital.id} position={[hospital.lat, hospital.lng]}>
            <Popup>
              <b>{hospital.name}</b><br />
              {hospital.address}<br />
              <a href={hospital.link} target="_blank" rel="noopener noreferrer">Website</a>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
}

export default HospitalMap;
