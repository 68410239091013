
function Auth(props) {
  alert(window.location.href);
  window.opener.postMessage(window.location.href, 'https://localhost:3000');
  window.close();
  return (
    <div>
      <h2>Auth Received!</h2>
    </div>
  );
}


export default Auth