import React, { useState } from 'react';
import { Box, Card, CardContent, Typography, Grid, TextField, Accordion, AccordionSummary, AccordionDetails, Radio, RadioGroup, FormControlLabel, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';



const hospitalData = [
  {
    "name": "Providence Sacred Heart Medical Center",
    "address": "101 W 8th Ave, Spokane, WA 99204",
    "city": "Spokane",
    "acute_beds": "N/A",
    "icu_beds": "N/A",
    "psych_beds": 145,
    "snf_beds": "N/A",
    "phone": "(509) 821-2000",
    "fax": "(509) 814-0301",
    "management": "For-Profit",
    "wa_state_member": "YES",
    "id": 1
  },
  {
    "name": "St. Lukes Rehabilitation Institute",
    "address": "711 SOUTH COWLEY STREET, SPOKANE WA 99202",
    "city": "Spokane",
    "acute_beds": 72,
    "icu_beds": "N/A",
    "psych_beds": "N/A",
    "snf_beds": "N/A",
    "phone": "(509) 838-4771",
    "fax": "(509) 838-7978",
    "management": "Private Not-for-Profit",
    "wa_state_member": "YES"
  },
  {
    "name": "Confluence Health Hospital",
    "address": "1201 S Miller St",
    "city": "Wenatchee",
    "acute_beds": 25,
    "icu_beds": "N/A",
    "psych_beds": "N/A",
    "snf_beds": "N/A",
    "phone": "(509) 725-7101",
    "fax": "(509) 725-2112",
    "management": "Public Hospital District",
    "wa_state_member": "YES",
    "id": 2
  },
  {
    "name": "Samaritan Hospital",
    "address": "801 E Wheeler Rd, Moses Lake WA 99159",
    "city": "Moses Lake",
    "acute_beds": 25,
    "icu_beds": "N/A",
    "psych_beds": "N/A",
    "snf_beds": "N/A",
    "phone": "(509) 982-2611",
    "fax": "(509) 982-2159",
    "management": "Public Hospital District",
    "wa_state_member": "YES",
    "id": 3
  },
  {
    "name": "Multicare Valley Hospital",
    "address": "12606 MISSION AVE E, SPOKANE WA 99210",
    "city": "Spokane Valley",
    "acute_beds": 84,
    "icu_beds": 10,
    "psych_beds": "N/A",
    "snf_beds": "N/A",
    "phone": "(509) 473-7495",
    "fax": "(509) 473-3661",
    "management": "Private Not-for-Profit",
    "wa_state_member": "YES"
  },
  {
    "name": "Multicare Deaconess Hospital",
    "address": "WEST 800 5TH AVE, SPOKANE WA 99210",
    "city": "Spokane",
    "acute_beds": 317,
    "icu_beds": 35,
    "psych_beds": "N/A",
    "snf_beds": "N/A",
    "phone": "(509) 458-0058",
    "fax": "(509) 473-7306",
    "management": "Private Not-for-Profit",
    "wa_state_member": "YES"
  },
  {
    "name": "Eastern State Hospital",
    "address": "MAPLE STREET P.O. BOX A, MEDICAL LAKE WA 99022",
    "city": "Medical Lake",
    "acute_beds": "N/A",
    "icu_beds": "N/A",
    "psych_beds": 297,
    "snf_beds": "N/A",
    "phone": "(509) 299-4351",
    "fax": "N/A",
    "management": "Federal/State",
    "wa_state_member": "YES"
  },
  {
    "name": "Mann-Grandstaff VA Medical Center",
    "address": "4815 N. ASSEMBLY ST., SPOKANE WA 99205",
    "city": "Spokane",
    "acute_beds": 66,
    "icu_beds": "N/A",
    "psych_beds": "N/A",
    "snf_beds": "N/A",
    "phone": "(509) 434-7000",
    "fax": "(509) 434-7119",
    "management": "Federal/State",
    "wa_state_member": "YES"
  },
  {
    "name": "Providence Holy Family Hospital",
    "address": "5633 NORTH LIDGERWOOD STREET, SPOKANE WA 99208",
    "city": "Spokane",
    "acute_beds": 170,
    "icu_beds": 12,
    "psych_beds": "N/A",
    "snf_beds": "N/A",
    "phone": "(509) 474-3040",
    "fax": "(509) 474-7324",
    "management": "Private Not-for-Profit",
    "wa_state_member": "YES"
  }
]
;
const HospitalList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredHospitals, setFilteredHospitals] = useState(hospitalData);
  const [selectedHospital, setSelectedHospital] = useState(null);
  const navigate = useNavigate();  // React Router's navigation hook

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = hospitalData.filter(hospital => hospital.name.toLowerCase().includes(value) || hospital.city.toLowerCase().includes(value));
    setFilteredHospitals(filtered);
  };

  const handleDetailsClick = (id) => {
    if(id){
      navigate(`/location-admin/${id}`);
    }
  };
  

  const handleSelectionChange = (event) => {
    setSelectedHospital(event.target.value);
  };

  const handleSubmit = () => {
    if (selectedHospital) {
      // Navigate to /transport-list
      navigate('/transport-list');
    } else {
      alert('Please select a hospital before submitting.');
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>Hospital Directory</Typography>

      {/* Submit Button */}
      <Box mb={1} mt={1}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!selectedHospital}
        >
          Submit
        </Button>
      </Box>
      {/* Search Bar */}
      <Box mb={3}>
        <TextField
          fullWidth
          label="Search by Hospital Name or City"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </Box>

      {/* Hospital Cards with Selection */}
      <RadioGroup value={selectedHospital} onChange={handleSelectionChange}>
        <Grid container spacing={3}>
          {filteredHospitals.map((hospital, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card>
                <CardContent>
                  <FormControlLabel
                    value={hospital.name}
                    control={<Radio />}
                    label={<Typography variant="h6">{hospital.name}</Typography>}
                  />
                   <Button onClick={() => handleDetailsClick(hospital.id)}>
                    Details
                  </Button>
                  <Typography variant="body2" color="textSecondary">{hospital.address}</Typography>
                  <Typography variant="body1" mt={1}>City: {hospital.city}</Typography>
                  <Typography variant="body1">Phone: {hospital.phone}</Typography>

                  {/* Expandable Details */}
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>View More Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="body2">Acute Beds: {hospital.acute_beds}</Typography>
                      <Typography variant="body2">ICU Beds: {hospital.icu_beds}</Typography>
                      <Typography variant="body2">Psych Beds: {hospital.psych_beds}</Typography>
                      <Typography variant="body2">SNF Beds: {hospital.snf_beds}</Typography>
                      <Typography variant="body2">Management: {hospital.management}</Typography>
                      <Typography variant="body2">WA State Member: {hospital.wa_state_member}</Typography>
                    </AccordionDetails>
                  </Accordion>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </RadioGroup>

    </Box>
  );
};


export default HospitalList;
