import { Cell, BarChart, LineChart, Line, Bar, Pie, PieChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Grid, Tabs, Tab, Box, Typography } from '@mui/material'
import * as React from 'react';
const data = [
  {
    name: 'Unit A',
    complete: 10,
    open: 12,
    amt: 2400,
  },
  {
    name: 'Unit B',
    complete: 13,
    open: 9,
    amt: 2210,
  },
  {
    name: 'Unit C',
    complete: 8,
    open: 16,
    amt: 2290,
  },
  {
    name: 'Unit D',
    complete: 5,
    open: 4,
    amt: 2000,
  },
  {
    name: 'Unit E',
    complete: 10,
    open: 1,
    amt: 2181,
  }
];

var today = new Date();
const offset = today.getTimezoneOffset();
today = new Date(today.getTime() - (offset * 60 * 1000));
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const lineData = [
  {
    name: new Date(today - 6 * (24 * 60 * 60 * 1000)).toISOString().split('T')[0],
    savings: 2400,
    opportunity: 4000,
    amt: 2400,
  },
  {
    name: new Date(today - 5 * (24 * 60 * 60 * 1000)).toISOString().split('T')[0],
    savings: 1390,
    opportunity: 3400,
    amt: 2210,
  },
  {
    name: new Date(today - 4 * (24 * 60 * 60 * 1000)).toISOString().split('T')[0],
    savings: 2000,
    opportunity: 9800,
    amt: 2290,
  },
  {
    name: new Date(today - 3 * (24 * 60 * 60 * 1000)).toISOString().split('T')[0],
    savings: 2780,
    opportunity: 3908,
    amt: 2000,
  },
  {
    name: new Date(today - 2 * (24 * 60 * 60 * 1000)).toISOString().split('T')[0],
    savings: 1890,
    opportunity: 4800,
    amt: 2181,
  },
  {
    name: new Date(today - 1 * (24 * 60 * 60 * 1000)).toISOString().split('T')[0],
    savings: 2390,
    opportunity: 3800,
    amt: 2500,
  },
  {
    name: new Date(today).toISOString().split('T')[0],
    savings: 3490,
    opportunity: 4300,
    amt: 2100,
  },
];

const obsToIps = [
  { name: 'Percent', value: 45 },
  { name: 'Percent', value: 55 },

];


const data01 = [
  { name: 'Open', value: 41 },
  { name: 'Completed', value: 65 },
  { name: 'Other', value: 3 }
];


const COLORS = ['#FFBB28', '#00C49F', '#FF0000', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

function Dashboard(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <h2>Dashboard</h2>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="Financial" {...a11yProps(0)} />
          <Tab label="Status Alerts" {...a11yProps(1)} />
          <Tab label="Resource Alerts" {...a11yProps(2)} />
          <Tab label="LOS Alerts" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Grid container>
          <Grid item xs={4}>
            <h4 style={{ textAlign: "center" }}>Cases per unit</h4>
            <BarChart
              width={450}
              height={300}
              data={data}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="open" stackId="a" fill="#8884d8" />
              <Bar dataKey="complete" stackId="a" fill="#82ca9d" />
            </BarChart>
          </Grid>
          <Grid item xs={4}>
            <h4 style={{ textAlign: "center" }}>Monitor Statuses</h4>


            <PieChart width={450} height={300}>
              <Pie
                data={data01}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                <Legend />
                {data01.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
          </Grid>
          <Grid item xs={4}>
            <h4 style={{ textAlign: "center" }}>Projected Savings</h4>
            <LineChart
              width={450}
              height={300}
              data={lineData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="opportunity" stroke="#8884d8" activeDot={{ r: 8 }} />
              <Line type="monotone" dataKey="savings" stroke="#82ca9d" />
            </LineChart>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container>

        <Grid item xs={12}>
          <h1>Status Alerts</h1>
        </Grid>

        <Grid item xs={2}>
          <div paper style={{ textAlign: "center", border: '1px dashed', padding: '15px' }}>
            <h4 style={{ margin: '3px' }}>Total Monitors</h4>
            <h2 style={{ margin: '3px' }}>125</h2>
            <h4 style={{ margin: '3px' }}>Resolved</h4>
            <h2 style={{ margin: '3px' }}>89</h2>
            <h4 style={{ margin: '3px' }}>Open</h4>
            <h2 style={{ margin: '3px' }}>12</h2>
          </div>

        </Grid>

        <Grid item xs={2}>
          <h4 style={{ textAlign: "center" }}>IP to OBS</h4>

          <PieChart width={300} height={200}>

            <Pie
              data={[
                { name: 'Percent', value: 25 },
                { name: 'Percent', value: 75 }]}
              cx="50%"
              cy="50%"
              labelLine={false}
              endAngle={0}
              startAngle={180}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              <Legend />
              {obsToIps.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={index > 0 ? '#808080' : COLORS[1]} />
              ))}
            </Pie>
          </PieChart>
        </Grid>
        <Grid item xs={2}>
          <h4 style={{ textAlign: "center" }}>IP to IP</h4>

          <PieChart width={300} height={200}>

            <Pie
              data={[
                { name: 'Percent', value: 45 },
                { name: 'Percent', value: 75 }]}
              cx="50%"
              cy="50%"
              labelLine={false}
              endAngle={0}
              startAngle={180}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              <Legend />
              {obsToIps.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={index > 0 ? '#808080' : COLORS[1]} />
              ))}
            </Pie>
          </PieChart>
        </Grid>
        <Grid item xs={2}>
          <h4 style={{ textAlign: "center" }}>OBS to IPS</h4>

          <PieChart width={300} height={200}>

            <Pie
              data={[
                { name: 'Percent', value: 25 },
                { name: 'Percent', value: 21 }]}
              cx="50%"
              cy="50%"
              labelLine={false}
              endAngle={0}
              startAngle={180}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              <Legend />
              {obsToIps.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={index > 0 ? '#808080' : COLORS[1]} />
              ))}
            </Pie>
          </PieChart>
        </Grid>
        <Grid item xs={2}>
          <h4 style={{ textAlign: "center" }}>No change</h4>

          <PieChart width={300} height={200}>

            <Pie
              data={obsToIps}
              cx="50%"
              cy="50%"
              labelLine={false}
              endAngle={0}
              startAngle={180}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              <Legend />
              {obsToIps.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={index > 0 ? '#808080' : COLORS[1]} />
              ))}
            </Pie>
          </PieChart>
        </Grid>

        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid container>

        <Grid item xs={12}>
          <h1>Resource Alerts</h1>
        </Grid>

        <Grid item xs={2}>
          <div paper style={{ textAlign: "center", border: '1px dashed', padding: '15px' }}>
            <h4 style={{ margin: '3px' }}>Total Monitors</h4>
            <h2 style={{ margin: '3px' }}>53</h2>
            <h4 style={{ margin: '3px' }}>Resolved</h4>
            <h2 style={{ margin: '3px' }}>42</h2>
            <h4 style={{ margin: '3px' }}>Open</h4>
            <h2 style={{ margin: '3px' }}>11</h2>
          </div>

        </Grid>

        <Grid item xs={2}>
          <h4 style={{ textAlign: "center" }}>Replaced with suggested</h4>

          <PieChart width={300} height={200}>

            <Pie
              data={[
                { name: 'Percent', value: 25 },
                { name: 'Percent', value: 10 }]}
              cx="50%"
              cy="50%"
              labelLine={false}
              endAngle={0}
              startAngle={180}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              <Legend />
              {obsToIps.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={index > 0 ? '#808080' : COLORS[1]} />
              ))}
            </Pie>
          </PieChart>
        </Grid>
        <Grid item xs={2}>
          <h4 style={{ textAlign: "center" }}>No change needed</h4>

          <PieChart width={300} height={200}>

            <Pie
              data={[
                { name: 'Percent', value: 25 },
                { name: 'Percent', value: 75 }]}
              cx="50%"
              cy="50%"
              labelLine={false}
              endAngle={0}
              startAngle={180}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              <Legend />
              {obsToIps.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={index > 0 ? '#808080' : COLORS[1]} />
              ))}
            </Pie>
          </PieChart>
        </Grid>
        <Grid item xs={2}>
          <h4 style={{ textAlign: "center" }}>No change - physician</h4>

          <PieChart width={300} height={200}>

            <Pie
              data={[
                { name: 'Percent', value: 5 },
                { name: 'Percent', value: 75 }]}
              cx="50%"
              cy="50%"
              labelLine={false}
              endAngle={0}
              startAngle={180}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              <Legend />
              {obsToIps.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={index > 0 ? '#808080' : COLORS[1]} />
              ))}
            </Pie>
          </PieChart>
        </Grid>
        <Grid item xs={2}>
          <h4 style={{ textAlign: "center" }}>Discontinued</h4>

          <PieChart width={300} height={200}>

            <Pie
              data={[
                { name: 'Percent', value: 5 },
                { name: 'Percent', value: 45 }]}
              cx="50%"
              cy="50%"
              labelLine={false}
              endAngle={0}
              startAngle={180}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              <Legend />
              {obsToIps.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={index > 0 ? '#808080' : COLORS[1]} />
              ))}
            </Pie>
          </PieChart>
        </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Grid container>
        <Grid item xs={12}>
          <h1>Length of Stay Alerts</h1>
        </Grid>

        <Grid item xs={2}>
          <div paper style={{ textAlign: "center", border: '1px dashed', padding: '15px' }}>
            <h4 style={{ margin: '3px' }}>Total Monitors</h4>
            <h2 style={{ margin: '3px' }}>37</h2>
            <h4 style={{ margin: '3px' }}>Resolved</h4>
            <h2 style={{ margin: '3px' }}>25</h2>
            <h4 style={{ margin: '3px' }}>Open</h4>
            <h2 style={{ margin: '3px' }}>12</h2>
          </div>

        </Grid>

        <Grid item xs={2}>
          <h4 style={{ textAlign: "center" }}>Replaced with suggested</h4>

          <PieChart width={300} height={200}>

            <Pie
              data={[
                { name: 'Percent', value: 25 },
                { name: 'Percent', value: 10 }]}
              cx="50%"
              cy="50%"
              labelLine={false}
              endAngle={0}
              startAngle={180}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              <Legend />
              {obsToIps.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={index > 0 ? '#808080' : COLORS[1]} />
              ))}
            </Pie>
          </PieChart>
        </Grid>
        <Grid item xs={2}>
          <h4 style={{ textAlign: "center" }}>No change needed</h4>

          <PieChart width={300} height={200}>

            <Pie
              data={[
                { name: 'Percent', value: 25 },
                { name: 'Percent', value: 75 }]}
              cx="50%"
              cy="50%"
              labelLine={false}
              endAngle={0}
              startAngle={180}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              <Legend />
              {obsToIps.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={index > 0 ? '#808080' : COLORS[1]} />
              ))}
            </Pie>
          </PieChart>
        </Grid>
        <Grid item xs={2}>
          <h4 style={{ textAlign: "center" }}>No change - physician</h4>

          <PieChart width={300} height={200}>

            <Pie
              data={[
                { name: 'Percent', value: 5 },
                { name: 'Percent', value: 75 }]}
              cx="50%"
              cy="50%"
              labelLine={false}
              endAngle={0}
              startAngle={180}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              <Legend />
              {obsToIps.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={index > 0 ? '#808080' : COLORS[1]} />
              ))}
            </Pie>
          </PieChart>
        </Grid>
        <Grid item xs={2}>
          <h4 style={{ textAlign: "center" }}>Discontinued</h4>

          <PieChart width={300} height={200}>

            <Pie
              data={[
                { name: 'Percent', value: 5 },
                { name: 'Percent', value: 45 }]}
              cx="50%"
              cy="50%"
              labelLine={false}
              endAngle={0}
              startAngle={180}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              <Legend />
              {obsToIps.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={index > 0 ? '#808080' : COLORS[1]} />
              ))}
            </Pie>
          </PieChart>
        </Grid>
        </Grid>
      </TabPanel>
    </div >
  );
}

export default Dashboard