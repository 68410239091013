

import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useSearchParams } from "react-router-dom";

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import UserIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';

import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import UserService from '../Services/user-service';
import UnitService from '../Services/hospital-unit-service';
import { Button, FormControl, MenuItem, Select } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',

  color: theme.palette.text.secondary,
}));

const style = {
  position: 'absolute',
  top: '50%',
  '& .MuiTextField-root': { m: 1, width: '25ch' },
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

/*const selectedUnit = {
  name: "Test Unit",
  isTelemetry: true,
  isIntensiveCare: true,
  CaseManagers: [
    {
      Name: "John Daker",
      Email: "John@hospital.com",
      Phone: "123-456-67890"
    },
    {
      Name: "Ralph Johnson",
      Email: "Ralph@hospital.com",
      Phone: "123-406-67890"
    }
  ]
};*/

let selectedUnit = {
  caseManagers: [

  ]
};

const selectedManager = {};




function UnitDetailsPage(props) {

  const [searchParams] = useSearchParams();
  const unitId = searchParams.get("unit");
  const [unit, setUnit] = React.useState(selectedUnit)

  const [webhookUrl, setWebhookUrl] = React.useState('');

  if (unitId && unitId !== '0' && !unit.id) {
    getUnit(unitId);
  }

  //const [setUsers] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let startAm = 'AM';
  let endAm = 'PM'
  const handleCloseSave = (event) => {
    const userService = new UserService();
    userService.createUser(selectedManager, selectedUnit.id).then((response) => {
      alert(JSON.stringify(response));
      getUnit(unit.id);
      setOpen(false)

    });
  };

  function getUnit(id) {
    const unitService = new UnitService();
    unitService.getUnit(id).then((units) => {
      units.json().then((d) => {
        selectedUnit = d;
        setUnit(d);
      });
    });
  }

  function handleValueChange(e) {
    switch (e.target.name) {
      case 'fullName':
        selectedManager.name = e.target.value;
        break;
      case 'email':
        selectedManager.email = e.target.value;
        break;
      case 'phone':
        selectedManager.telephone = e.target.value;
        break;
      case 'unitName':
        selectedUnit.name = e.target.value;
        break;
      case 'webhookUrl':
        setWebhookUrl(e.target.value);
        selectedUnit.webhookUrl = webhookUrl;
        break;
      default:
        break;
    }
  }

  /*
    const updateUsers = (unit) => {
      if (selectedUnit.id) {
        const userService = new UserService();
          userService.getUsers(selectedUnit.id).then((users) => {
            users.json().then((d) => {
              setUsers(d)
            });
          });
        }
    }*/

  const handleChange = (e) => {
    this.selectedManager.name = e.target.value;
  }

  const saveUnit = () => {
    const unitService = new UnitService();
    if(selectedUnit.id){
      unitService.updateUnit(selectedUnit).then((units) => {
        alert('Hospital Unit Updated.');
      });
    }else{
      unitService.createUnit(selectedUnit).then((units) => {
        units.json().then((d) => {
          selectedUnit = d;
          getUnit(d.id);
          alert('Hospital Unit Created.');
          //updateUsers(selectedUnit);
        });
      });
    }
    
  }



  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={2}>
        </Grid>
        <Grid item elevation={3} xs={8}>
          <Item>
            <TextField InputLabelProps={{ shrink: true }} name="unitName" onChange={handleValueChange} value={selectedUnit.name} id="outlined-basic" label="Unit Name" variant="outlined" />
            <Button variant="contained" onClick={saveUnit}>Save</Button>
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={selectedUnit.isTelemetry} />} label="Telemetry Unit" />
              <FormControlLabel control={<Checkbox checked={selectedUnit.isIntensiveCare} />} label="Intensive Care Unit" />
              <TextField InputLabelProps={{ shrink: true }} name="webhookUrl" onChange={handleValueChange} value={webhookUrl} id="outlined-basic" label="Webhook URL" variant="outlined" />

            </FormGroup>
            <List component="nav" aria-label="main mailbox folders"
              subheader={<ListSubheader>Case Managers
                <IconButton onClick={handleOpen}>
                  <AddIcon />
                </IconButton>
              </ListSubheader>}
            >
              {unit.caseManagers.map((manager, index) => (
                <ListItemButton
                  onClick={(event) => handleChange(event, 0)}
                >
                  <ListItemIcon>
                    <UserIcon />
                  </ListItemIcon>
                  <ListItemText primary={manager.name} />
                </ListItemButton>
              ))}

            </List>
          </Item>

        </Grid>
      </Grid>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Grid container spacing={2} sx={style}>
            <Grid item xs={4}>
              <TextField name="fullName" onChange={handleValueChange} value={selectedManager.name} id="outlined-basic" label="Manager Name" variant="outlined" />
            </Grid>
            <Grid item xs={4}>
              <TextField name="email" onChange={handleValueChange} value={selectedManager.email} id="outlined-basic" label="Manager Email" variant="outlined" />
            </Grid>
            <Grid item xs={4}>
              <TextField name="phone" onChange={handleValueChange} value={selectedManager.phone} id="outlined-basic" label="Manager Phone" variant="outlined" />
            </Grid>
            <Grid item xs={10}>
              <h3>Scheduling</h3>
              <Grid container>

                {daysOfWeek.map((day, index) => (
                  <Grid item>
                    <FormGroup row={true}>
                      <FormControlLabel style={{ width: 200 }} control={<Checkbox />} label={day} />
                      <TextField style={{ width: 100 }} label="Start"></TextField>
                      <FormControl style={{ justifyContent: 'center' }}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={startAm}
                        >
                          <MenuItem value={'AM'}>AM</MenuItem>
                          <MenuItem value={'PM'}>PM</MenuItem>
                        </Select>
                      </FormControl>

                      <TextField labelId="am-pm-label" style={{ width: 100 }} label="Finish"></TextField>
                      <FormControl style={{ justifyContent: 'center' }}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={endAm}
                        >
                          <MenuItem value={'AM'}>AM</MenuItem>
                          <MenuItem value={'PM'}>PM</MenuItem>
                        </Select>
                      </FormControl>
                    </FormGroup>

                  </Grid>
                ))}

              </Grid>
            </Grid>

            <Grid item xs={2}>
              <Button variant="contained" onClick={handleCloseSave}>Save</Button>
            </Grid>
          </Grid>
        </Fade>
      </Modal>
    </div>
  );
}

export default UnitDetailsPage