import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import FHIR from 'fhirclient';

const root = ReactDOM.createRoot(document.getElementById('root'));

const smartLaunch = () => {
  // Authorize application
  try{
  FHIR.oauth2
    .init({
      clientId: 'e25d6b95-6287-48e7-a4f4-25d3f860639b',
      redirectUri: '/feed',
      scope: 'launch/patient user/NutritionOrder.read user/ServiceRequest.read  user/MedicationRequest.read user/Patient.read user/Encounter.read user/Observation.read launch online_access openid profile'
    })
    .then(client => {
      root.render(
        <React.StrictMode>
          <App client={client} />
        </React.StrictMode>
      );
    }).catch(error => {
      root.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>
      );
    });
  } catch {
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  }
};

smartLaunch();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
