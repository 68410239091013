export default function TriggerService(){
    var api = 'https://jbh-fhir.azurewebsites.net/api/Triggers';
    //var api = 'https://localhost:44304/api/Triggers';

    this.getTriggers = () => {
        return fetch(api, {
            
          })
    }

    this.getTrigger = (id) => {
        return fetch(api + "/" + id, {
            "method": "GET",
            "headers": {
              "content-type": "application/json",
              "accept": "application/json"
            }
          })
    }

    this.updateTrigger = (trigger) => {
       return fetch(api, {
            "method": "PUT",
           
            "body": JSON.stringify({
              name: this.state.name,
              notes: this.state.notes
            })
          })
    }

    this.createTrigger = (trigger) => {
        return fetch(api, {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json"
              },
            "body": JSON.stringify(trigger)
          })
    }
}