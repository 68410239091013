function analyzeEncounters(enc) {
    var alerts = [];
    for (var i in enc) {
      var encounter = enc[i];
      if (encounter.status === 'in-progress') {
        if (encounter.type[0].text === "Inpatient") {
          if (encounter.period) {
            var p = encounter.period.start;
            var now = new Date();
            var pDate = new Date(p);
            var diff = (now - pDate) / (1000 * 3600 * 24);
            if (diff && diff > 2) {
              alerts.push("Encounter number " + encounter.id + " Patient has open inpatient encounter lasting longer than 2 days. Total time: " + Math.round(diff) + ' days.');
            }
          }
        }
      }
    }
    return alerts;
  }

export default analyzeEncounters;