import * as React from 'react';
import List from '@mui/material/List';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import { deepOrange, green } from '@mui/material/colors';
import TriggerService from './Services/trigger-service';
import { ListItemSecondaryAction, MenuItem, IconButton, Menu, Modal, Backdrop,Grid,Fade,Button,TextField, LinearProgress } from '@mui/material';

import SettingsIcon from '@mui/icons-material/Settings';
export default function TriggerFeedList() {
    const style = {
        position: 'absolute',
        top: '50%',
        '& .MuiTextField-root': { m: 1, width: '25ch' },
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
    let menu;

    const triggerTypes = ['Status', 'LOS', 'Resource']

    const handleModalOpen = () => {
        handleMoreClose();    
        setModalOpen(true)
    };
    const handleModalClose = () => setModalOpen(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [moreAnchorEl, setMoreAnchorEl] = React.useState(null);
    const [selectedRow, setSelectedRow] = React.useState(null);
    const moreOpen = Boolean(moreAnchorEl);
    const [triggers, setTriggers] = React.useState([]);
    const [value, setValue] = React.useState(0);
    const [triggersLoaded, setTriggersLoaded] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
    React.useEffect(() => {
        if (!triggers.length) {
            const triggerService = new TriggerService();
            triggerService.getTriggers().then((ms) => {
                setTriggersLoaded(true);
                ms.json().then((d) => {
                    setTriggers(d)
                });
            });
        }
    });

    const handleMoreClick = (event,row) => {
        if(selectedRow){
            console.log("row");
        }
        setSelectedRow(row);
        setMoreAnchorEl(event.currentTarget);
      };

      const buildMenu = () => {

        menu = buildStatusMenuItems();
    
      }

      const handleMoreClose = () => {
        setMoreAnchorEl(null);
      };

      const randomNumber = () => {
        return Math.floor(Math.random() * (10 - 0 + 1)) + 0;
      }
      
  const buildStatusMenuItems = () => {
    return (
      <Menu
                    id="more-menu"
                    anchorEl={moreAnchorEl}
                    open={moreOpen}
                    onClose={handleMoreClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={handleModalOpen}>Configure</MenuItem>
                    <MenuItem onClick={handleMoreClose}>Turn Off</MenuItem>
                  </Menu>
    )
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function handleValueChange(e) {
    switch (e.target.name) {
      
      default:
        break;
    }
  }

  buildMenu();
    return (
        <div>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="Status Triggers" {...a11yProps(0)} />
          <Tab label="Length of Stay Triggers" {...a11yProps(1)} />
          <Tab label="Resource Triggers" {...a11yProps(2)} />
        </Tabs>
      </Box>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={modalOpen}
          onClose={handleModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={modalOpen}>
            <Grid container spacing={2} sx={style}>
              <Grid item xs={4}>
                
              <p>Trigger Variables</p>
              
              </Grid>
              
              <Grid item xs={12}></Grid>
              {selectedRow ? selectedRow.variables.map((v) => {
                return(
                  <Grid item xs={4}>
                  <TextField name={v.name} onChange={handleValueChange} value={v.value} id="outlined-basic" label={v.name} variant="outlined" />
                </Grid>)
              }) : <p>Trigger has no variables.</p>}

              <Grid item xs={12}></Grid>
              <Grid item xs={4}>
                
                <p>Citations</p>
                </Grid>

                <Grid item xs={12}></Grid>
                {selectedRow ? selectedRow.citations.map((c) => {
                  return(
                    <Grid item xs={4}>
                    <a rel="noreferrer" target="_blank" href={c.url}>{c.name}</a>
                  </Grid>)
                }) : <p>Trigger has no citations</p>}
  
              <Grid item xs={10}></Grid>
              <Grid item xs={2}>
                <Button variant="contained" onClick={handleModalClose}>Save</Button>
              </Grid>
            </Grid>
          </Fade>
        </Modal>
        <List sx={{ width: '100%', maxWidth: 1200, bgcolor: 'background.paper' }}>
            
            {menu}
            {!triggersLoaded ? <LinearProgress /> : ''}
            {triggers.filter(c => c.program === triggerTypes[value]).map((row) => {
                return (
                    <ListItem>
                        <ListItemSecondaryAction>
                            <IconButton aria-label="Actions"
                    id="more-button"
                    aria-controls={moreOpen ? 'more-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={moreOpen ? 'true' : undefined}
                    onClick={event => handleMoreClick(event,row)}>
                                <SettingsIcon />
                                
                            </IconButton>
                        </ListItemSecondaryAction>
                        
                            <ListItemAvatar>
                                {row.status === 'Active' ? <Avatar sx={{ bgcolor: green[500] }}>
                                    <AlarmOnIcon />
                                </Avatar> : <Avatar sx={{ bgcolor: deepOrange[500] }}>
                                    <AlarmOffIcon />
                                </Avatar>}
                            </ListItemAvatar>
                            <ListItemText primary={row.name} secondary={
                                <div>
                                    <p className='timestamp'>Triggered {randomNumber()} times in past week</p>
                                    <p className='feed-message'><strong>Trigger Description</strong> {row.description}</p>
                                    <p className='feed-message'><strong>Trigger Action</strong> {row.action}</p>
                                    <p className='feed-message'><strong>Target Metrics:</strong>
                                    {row.metrics.map((metric) =>{
                                       return <Button title={metric.name}>{metric.abbreviation}</Button>
                                    })}
                                    
                                    </p>
                                    
                                </div>
                            } />
                    </ListItem>
                )
            })}



            {buildMenu()}
        </List>
        </div>
    );
}
