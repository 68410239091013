export default function MonitorService(){
   var api = 'https://jbh-fhir.azurewebsites.net/api/Monitors';
     //  var api = 'https://localhost:44304/api/Monitors';

    this.getMonitors = (hospitalUnit) => {
        var url = api + "?unit=" + hospitalUnit

        return fetch(url, {
            
          })
    }

    this.getMonitor = (id) => {
        return fetch(api, {
            "method": "POST",
            "headers": {
              "content-type": "application/json",
              "accept": "application/json"
            },
            "body": JSON.stringify({
              name: this.state.name,
              notes: this.state.notes
            })
          })
    }

    this.updateMonitor = (trigger) => {
       return fetch(api, {
            "method": "PUT",
           
            "body": JSON.stringify({
              name: this.state.name,
              notes: this.state.notes
            })
          })
    }

    this.createMonitor = (trigger) => {
        return fetch(api, {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json"
              },
            "body": JSON.stringify(trigger)
          })
    }

    this.triggerAlert = (monitorId) => {
      return fetch(api + "/sendAlert", {
          "method": "POST",
          "headers": {
              "content-type": "application/json",
              "accept": "application/json"
            },
          "body": JSON.stringify({monitorId})
        })
  }
}