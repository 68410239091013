
import FeedList from "../FeedList"
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CheckList from "../CheckList"
import { useState } from 'react';
import analyzeEncounters from "../Triggers/inpatient-trigger";
import invalidIvCheck from "../Triggers/invalid-iv-trigger";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',

  color: theme.palette.text.secondary,
}));

function Home(props) {
  /*props.patient.api.fetchAll({
    type: 'Encounter',
  })*/
  const [alerts, setAlerts] = useState([]);

  const [loading, setLoading] = React.useState(true);
  if (loading) {
    if(props.client){
    invalidIvCheck(props.client);
    props.client.request("Encounter?patient=" + props.client.patient.id + '&status=in-progress', { pageLimit: 0 })
      .then(
        (data) => {
          var encounterList = [];
          for (var i in data) {
            var baseOb = data[i];
            for (var j in baseOb.entry) {
              var res = baseOb.entry[j];
              encounterList.push(res.resource);
            }
          }
          var als = analyzeEncounters(encounterList);
          setAlerts(als);
          setLoading(false);
        });
      }else{
        setLoading(false);
      }
  }
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item>
            <CheckList />
          </Item>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item elevation={3} xs={8}>
          <Item>
            <h1>FEED</h1>
            <FeedList isLoading={loading} alerts={alerts} />
          </Item>
        </Grid>
      </Grid>
    </div>
  );

}

export default Home