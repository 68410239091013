
import TriggerFeedList from "../TriggerFeedList"

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { PieChart, Pie, Cell, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer } from 'recharts';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Switch from '@mui/material/Switch';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import * as React from 'react';



var today = new Date();
const offset = today.getTimezoneOffset();
today = new Date(today.getTime() - (offset * 60 * 1000));
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const obsToIps = [
  { name: 'Percent', value: 45 },
  { name: 'Percent', value: 55 },

];



const COLORS = ['#FFBB28', '#00C49F', '#FF0000', '#FF8042'];



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  
  color: theme.palette.text.secondary,
}));

function TriggerPage() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
function createData(trigger, description) {
  return { trigger, description, enabled: false };
}

const rows = [
  createData('Telemetry', 'Checks to ensure telemetry units are being used efficiently'),
  createData('Palliative Care', 'Checks for potential patient conversions to palliative care'),
  createData('Pharmacy', 'Checks for IV to oral medication conversion'),
];
  var today = new Date();
  const lineData = [
    {
      name: new Date(today - 6 * (24 * 60 * 60 * 1000)).toISOString().split('T')[0],
      beds: 240,
      threshold: 180,
      amt: 2400,
    },
    {
      name: new Date(today - 5 * (24 * 60 * 60 * 1000)).toISOString().split('T')[0],
      beds: 139,
      threshold: 180,
      amt: 2210,
    },
    {
      name: new Date(today - 4 * (24 * 60 * 60 * 1000)).toISOString().split('T')[0],
      beds: 200,
      threshold: 180,
      amt: 2290,
    },
    {
      name: new Date(today - 3 * (24 * 60 * 60 * 1000)).toISOString().split('T')[0],
      beds: 278,
      threshold: 180,
      amt: 2000,
    },
    {
      name: new Date(today - 2 * (24 * 60 * 60 * 1000)).toISOString().split('T')[0],
      beds: 189,
      threshold: 180,
      amt: 2181,
    },
    {
      name: new Date(today - 1 * (24 * 60 * 60 * 1000)).toISOString().split('T')[0],
      beds: 429,
      threshold: 180,
      amt: 2500,
    },
    {
      name: new Date(today).toISOString().split('T')[0],
      beds: 349,
      threshold: 180,
      amt: 2100,
    },
  ];

  const losLineData = [
    {
      name: new Date(today - 6 * (24 * 60 * 60 * 1000)).toISOString().split('T')[0],
      LOS: 2.40,
      threshold: 4.00,
      amt: 2400,
    },
    {
      name: new Date(today - 5 * (24 * 60 * 60 * 1000)).toISOString().split('T')[0],
      LOS: 1.39,
      threshold: 4.00,
      amt: 2210,
    },
    {
      name: new Date(today - 4 * (24 * 60 * 60 * 1000)).toISOString().split('T')[0],
      LOS: 2.00,
      threshold: 4.00,
      amt: 2290,
    },
    {
      name: new Date(today - 3 * (24 * 60 * 60 * 1000)).toISOString().split('T')[0],
      LOS: 2.78,
      threshold: 4.00,
      amt: 2000,
    },
    {
      name: new Date(today - 2 * (24 * 60 * 60 * 1000)).toISOString().split('T')[0],
      LOS: 1.89,
      threshold: 4.00,
      amt: 2181,
    },
    {
      name: new Date(today - 1 * (24 * 60 * 60 * 1000)).toISOString().split('T')[0],
      LOS: 4.29,
      threshold: 4.00,
      amt: 2500,
    },
    {
      name: new Date(today).toISOString().split('T')[0],
      LOS: 5.49,
      threshold: 4.00,
      amt: 2100,
    },
  ];

  return (
    <div style={{overflow: "auto", height: "100%"}}>
    <Grid container spacing={2}>
      <Grid item xs={6}>
      <Item>
            <h2>Avg Length of Stay</h2>
            <ResponsiveContainer  width="100%" height={300}>
   <LineChart
              data={losLineData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="threshold" stroke="#ff0000" 
    strokeDasharray="5 5" />
              <Line type="monotone" dataKey="LOS" stroke="#82ca9d" />
            </LineChart>
            </ResponsiveContainer>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Trigger</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell align="right">Enabled</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.trigger}>
                        <TableCell component="th" scope="row">
                          {row.trigger}
                        </TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell align="right">
                          <Switch
                            checked={row.enabled}
                            onChange={() => (row.enabled = !row.enabled)}
                            name="enabled"
                            color="primary"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
          </Item>
      </Grid>
      <Grid item xs={6}>
        <Item>
          <h2>Bed Capacity</h2>
          
          <ResponsiveContainer  width="100%" height={300}>
 <LineChart
            width={550}
            height={300}
            data={lineData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="threshold" stroke="#ff0000" 
  strokeDasharray="5 5" />
            <Line type="monotone" dataKey="beds" stroke="#82ca9d" />
          </LineChart>
          </ResponsiveContainer>

    
          <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Trigger</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell align="right">Enabled</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.trigger}>
                      <TableCell component="th" scope="row">
                        {row.trigger}
                      </TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell align="right">
                        <Switch
                          checked={row.enabled}
                          onChange={() => (row.enabled = !row.enabled)}
                          name="enabled"
                          color="primary"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
        </Item>
      </Grid>
    </Grid>
    <br/>
    <Grid container>
      <Grid item xs={12}>
        <Item>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Status Alerts" {...a11yProps(0)} />
          <Tab label="Resource Alerts" {...a11yProps(1)} />
          <Tab label="LOS Alerts" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Grid container>

        <Grid item xs={12}>
          <h1>Status Alerts</h1>
        </Grid>

        <Grid item xs={2}>
          <div paper style={{ textAlign: "center", border: '1px dashed', padding: '15px' }}>
            <h4 style={{ margin: '3px' }}>Total Monitors</h4>
            <h2 style={{ margin: '3px' }}>125</h2>
            <h4 style={{ margin: '3px' }}>Resolved</h4>
            <h2 style={{ margin: '3px' }}>89</h2>
            <h4 style={{ margin: '3px' }}>Open</h4>
            <h2 style={{ margin: '3px' }}>12</h2>
          </div>

        </Grid>

        <Grid item xs={2}>
          <h4 style={{ textAlign: "center" }}>IP to OBS</h4>

          <PieChart width={300} height={200}>

            <Pie
              data={[
                { name: 'Percent', value: 25 },
                { name: 'Percent', value: 75 }]}
              cx="50%"
              cy="50%"
              labelLine={false}
              endAngle={0}
              startAngle={180}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              <Legend />
              {obsToIps.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={index > 0 ? '#808080' : COLORS[1]} />
              ))}
            </Pie>
          </PieChart>
        </Grid>
        <Grid item xs={2}>
          <h4 style={{ textAlign: "center" }}>IP to IP</h4>

          <PieChart width={300} height={200}>

            <Pie
              data={[
                { name: 'Percent', value: 45 },
                { name: 'Percent', value: 75 }]}
              cx="50%"
              cy="50%"
              labelLine={false}
              endAngle={0}
              startAngle={180}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              <Legend />
              {obsToIps.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={index > 0 ? '#808080' : COLORS[1]} />
              ))}
            </Pie>
          </PieChart>
        </Grid>
        <Grid item xs={2}>
          <h4 style={{ textAlign: "center" }}>OBS to IPS</h4>

          <PieChart width={300} height={200}>

            <Pie
              data={[
                { name: 'Percent', value: 25 },
                { name: 'Percent', value: 21 }]}
              cx="50%"
              cy="50%"
              labelLine={false}
              endAngle={0}
              startAngle={180}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              <Legend />
              {obsToIps.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={index > 0 ? '#808080' : COLORS[1]} />
              ))}
            </Pie>
          </PieChart>
        </Grid>
        <Grid item xs={2}>
          <h4 style={{ textAlign: "center" }}>No change</h4>

          <PieChart width={300} height={200}>

            <Pie
              data={obsToIps}
              cx="50%"
              cy="50%"
              labelLine={false}
              endAngle={0}
              startAngle={180}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              <Legend />
              {obsToIps.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={index > 0 ? '#808080' : COLORS[1]} />
              ))}
            </Pie>
          </PieChart>
        </Grid>

        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container>

        <Grid item xs={12}>
          <h1>Resource Alerts</h1>
        </Grid>

        <Grid item xs={2}>
          <div paper style={{ textAlign: "center", border: '1px dashed', padding: '15px' }}>
            <h4 style={{ margin: '3px' }}>Total Monitors</h4>
            <h2 style={{ margin: '3px' }}>53</h2>
            <h4 style={{ margin: '3px' }}>Resolved</h4>
            <h2 style={{ margin: '3px' }}>42</h2>
            <h4 style={{ margin: '3px' }}>Open</h4>
            <h2 style={{ margin: '3px' }}>11</h2>
          </div>

        </Grid>

        <Grid item xs={2}>
          <h4 style={{ textAlign: "center" }}>Replaced with suggested</h4>

          <PieChart width={300} height={200}>

            <Pie
              data={[
                { name: 'Percent', value: 25 },
                { name: 'Percent', value: 10 }]}
              cx="50%"
              cy="50%"
              labelLine={false}
              endAngle={0}
              startAngle={180}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              <Legend />
              {obsToIps.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={index > 0 ? '#808080' : COLORS[1]} />
              ))}
            </Pie>
          </PieChart>
        </Grid>
        <Grid item xs={2}>
          <h4 style={{ textAlign: "center" }}>No change needed</h4>

          <PieChart width={300} height={200}>

            <Pie
              data={[
                { name: 'Percent', value: 25 },
                { name: 'Percent', value: 75 }]}
              cx="50%"
              cy="50%"
              labelLine={false}
              endAngle={0}
              startAngle={180}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              <Legend />
              {obsToIps.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={index > 0 ? '#808080' : COLORS[1]} />
              ))}
            </Pie>
          </PieChart>
        </Grid>
        <Grid item xs={2}>
          <h4 style={{ textAlign: "center" }}>No change - physician</h4>

          <PieChart width={300} height={200}>

            <Pie
              data={[
                { name: 'Percent', value: 5 },
                { name: 'Percent', value: 75 }]}
              cx="50%"
              cy="50%"
              labelLine={false}
              endAngle={0}
              startAngle={180}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              <Legend />
              {obsToIps.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={index > 0 ? '#808080' : COLORS[1]} />
              ))}
            </Pie>
          </PieChart>
        </Grid>
        <Grid item xs={2}>
          <h4 style={{ textAlign: "center" }}>Discontinued</h4>

          <PieChart width={300} height={200}>

            <Pie
              data={[
                { name: 'Percent', value: 5 },
                { name: 'Percent', value: 45 }]}
              cx="50%"
              cy="50%"
              labelLine={false}
              endAngle={0}
              startAngle={180}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              <Legend />
              {obsToIps.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={index > 0 ? '#808080' : COLORS[1]} />
              ))}
            </Pie>
          </PieChart>
        </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid container>
        <Grid item xs={12}>
          <h1>Length of Stay Alerts</h1>
        </Grid>

        <Grid item xs={2}>
          <div paper style={{ textAlign: "center", border: '1px dashed', padding: '15px' }}>
            <h4 style={{ margin: '3px' }}>Total Monitors</h4>
            <h2 style={{ margin: '3px' }}>37</h2>
            <h4 style={{ margin: '3px' }}>Resolved</h4>
            <h2 style={{ margin: '3px' }}>25</h2>
            <h4 style={{ margin: '3px' }}>Open</h4>
            <h2 style={{ margin: '3px' }}>12</h2>
          </div>

        </Grid>

        <Grid item xs={2}>
          <h4 style={{ textAlign: "center" }}>Replaced with suggested</h4>

          <PieChart width={300} height={200}>

            <Pie
              data={[
                { name: 'Percent', value: 25 },
                { name: 'Percent', value: 10 }]}
              cx="50%"
              cy="50%"
              labelLine={false}
              endAngle={0}
              startAngle={180}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              <Legend />
              {obsToIps.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={index > 0 ? '#808080' : COLORS[1]} />
              ))}
            </Pie>
          </PieChart>
        </Grid>
        <Grid item xs={2}>
          <h4 style={{ textAlign: "center" }}>No change needed</h4>

          <PieChart width={300} height={200}>

            <Pie
              data={[
                { name: 'Percent', value: 25 },
                { name: 'Percent', value: 75 }]}
              cx="50%"
              cy="50%"
              labelLine={false}
              endAngle={0}
              startAngle={180}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              <Legend />
              {obsToIps.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={index > 0 ? '#808080' : COLORS[1]} />
              ))}
            </Pie>
          </PieChart>
        </Grid>
        <Grid item xs={2}>
          <h4 style={{ textAlign: "center" }}>No change - physician</h4>

          <PieChart width={300} height={200}>

            <Pie
              data={[
                { name: 'Percent', value: 5 },
                { name: 'Percent', value: 75 }]}
              cx="50%"
              cy="50%"
              labelLine={false}
              endAngle={0}
              startAngle={180}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              <Legend />
              {obsToIps.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={index > 0 ? '#808080' : COLORS[1]} />
              ))}
            </Pie>
          </PieChart>
        </Grid>
        <Grid item xs={2}>
          <h4 style={{ textAlign: "center" }}>Discontinued</h4>

          <PieChart width={300} height={200}>

            <Pie
              data={[
                { name: 'Percent', value: 5 },
                { name: 'Percent', value: 45 }]}
              cx="50%"
              cy="50%"
              labelLine={false}
              endAngle={0}
              startAngle={180}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              <Legend />
              {obsToIps.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={index > 0 ? '#808080' : COLORS[1]} />
              ))}
            </Pie>
          </PieChart>
        </Grid>
        </Grid>
      </TabPanel>
      </Item>
      </Grid>
    </Grid>
    <br/>
    <br/>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          
        </Grid>
        <Grid item xs={8}>
          
          <Item>
            <h1>Available Triggers</h1>
            <TriggerFeedList />
          </Item>
        </Grid>
        <Grid item xs={2}>
        </Grid>
      </Grid>
    </div>
  );
}

export default TriggerPage