import * as React from 'react';
import List from '@mui/material/List';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import WarningIcon from '@mui/icons-material/Warning';
import { deepOrange, green } from '@mui/material/colors';
import HospitalUnitService from './Services/hospital-unit-service';
import { Paper, Autocomplete,ListItemSecondaryAction, MenuItem, IconButton, Menu, Modal, Backdrop, Grid, Fade, Button, TextField, LinearProgress } from '@mui/material';

import SettingsIcon from '@mui/icons-material/Settings';
export default function PatientScannerList() {
  const style = {
    position: 'absolute',
    top: '50%',
    '& .MuiTextField-root': { m: 1, width: '25ch' },
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  let menu;

  const triggerTypes = ['Length of Stay', 'Resource Utilization', 'Status']

  const handleModalOpen = () => {
    handleMoreClose();
    setModalOpen(true)
  };
  const handleModalClose = () => setModalOpen(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [moreAnchorEl, setMoreAnchorEl] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const moreOpen = Boolean(moreAnchorEl);
  const [scans, setScans] = React.useState([]);
  const [patients, setPatients] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [scansLoaded, setScansLoaded] = React.useState(false);
  const [patientsLoaded, setPatientsLoaded] = React.useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function scanPatient() {
    setPatientsLoaded(true);    
    patientChange(null,null);

    if (!scans.length) {
      const hospitalUnitService = new HospitalUnitService();
      let results = hospitalUnitService.getScanResults();
    //  setScansLoaded(true);
      setScans(results);
    }
  }

  React.useEffect(() => {
   
  });

  const handleMoreClick = (event, row) => {
    if (selectedRow) {
      console.log("row");
    }
    setSelectedRow(row);
    setMoreAnchorEl(event.currentTarget);
  };

  const buildMenu = () => {

    menu = buildStatusMenuItems();

  }

  const handleMoreClose = () => {
    setMoreAnchorEl(null);
  };
  if (!patients.length) {
    const hospitalUnitService = new HospitalUnitService();
    let pats = hospitalUnitService.getPatients();
    setPatients(pats);
  }
  const buildStatusMenuItems = () => {
    return (
      <Menu
        id="more-menu"
        anchorEl={moreAnchorEl}
        open={moreOpen}
        onClose={handleMoreClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleModalOpen}>Configure</MenuItem>
        <MenuItem onClick={handleMoreClose}>Turn Off</MenuItem>
      </Menu>
    )
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function handleValueChange(e) {
    switch (e.target.name) {

      default:
        break;
    }
  }




  function mainList(scans) {
    return(

      <List sx={{ width: '100%', maxWidth: 1200, bgcolor: '#eee' }}>

        {menu}
        {!scansLoaded ? <Paper><h2>Scanning Patients...</h2> <LinearProgress /></Paper> : ''}
        {!scansLoaded ? '' : scans.filter(c => c.category === triggerTypes[value]).map((row) => {
          return (
            <ListItem>
              <ListItemSecondaryAction>
                <IconButton aria-label="Actions"
                  id="more-button"
                  aria-controls={moreOpen ? 'more-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={moreOpen ? 'true' : undefined}
                  onClick={event => handleMoreClick(event, row)}>
                  <SettingsIcon />

                </IconButton>
              </ListItemSecondaryAction>

              <ListItemAvatar>
                {row.status === 'Active' ? <Avatar sx={{ bgcolor: green[500] }}>
                  <WarningIcon />
                </Avatar> : <Avatar sx={{ bgcolor: deepOrange[500] }}>
                  <WarningIcon />
                </Avatar>}
              </ListItemAvatar>
              <ListItemText primary={row.name} secondary={
                <div>
                  <p className='feed-message'><strong> {row.title}</strong></p>

                </div>
              } />
            </ListItem>
          )
        })}



        {buildMenu()}
      </List>

    );
  }

  const buildPatientLoading = () => {
    return <LinearProgress ></LinearProgress>
  }

  
  const patientChange = (e, v) => {
    setScansLoaded(false);
    setTimeout( () => {
      setScansLoaded(true);
    },3000)
  }

  buildMenu();
  return (
    <div class="patient-list">

      <Paper className='patient-list-paper'>
        <Grid container>
          <Grid item xs={4}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={patients}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Patient" />}
        />

        {patientsLoaded ? null : buildPatientLoading()}
        </Grid>
        <Grid xs={4}>
        <Button
         onClick={scanPatient}
        variant="contained"
        >Scan Patient</Button>
        </Grid>
        </Grid>
      </Paper>
      
      {!scansLoaded ? '' : <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Forgotten Diseases and Treatments" {...a11yProps(0)} />
          <Tab label="Abnormal Actionable Metrics" {...a11yProps(1)} />
          <Tab label="Social Determinants of Health" {...a11yProps(2)} />
        </Tabs>
      </Box>}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
          <Grid container spacing={2} sx={style}>
            <Grid item xs={4}>

              <p>Trigger Variables</p>

            </Grid>

            <Grid item xs={12}></Grid>
            {selectedRow ? selectedRow.variables.map((v) => {
              return (
                <Grid item xs={4}>
                  <TextField name={v.name} onChange={handleValueChange} value={v.value} id="outlined-basic" label={v.name} variant="outlined" />
                </Grid>)
            }) : <p>Trigger has no variables.</p>}

            <Grid item xs={12}></Grid>
            <Grid item xs={4}>

              <p>Citations</p>
            </Grid>

            <Grid item xs={12}></Grid>
            {selectedRow ? selectedRow.citations.map((c) => {
              return (
                <Grid item xs={4}>
                  <a rel="noreferrer" target="_blank" href={c.url}>{c.name}</a>
                </Grid>)
            }) : <p>Trigger has no citations</p>}

            <Grid item xs={10}></Grid>
            <Grid item xs={2}>
              <Button variant="contained" onClick={handleModalClose}>Save</Button>
            </Grid>
          </Grid>
        </Fade>
      </Modal>

      {scansLoaded ? mainList(scans) : scans.length ? buildPatientLoading() : ''}
    </div>
  );
}
