import { Card, CardContent, Typography, Button, Grid, TextField, Stepper, Step, StepLabel } from '@mui/material';
import React from 'react';

const TransferReceivePage = () => {
  const steps = ['Requested', 'Reviewing', 'Accepted', 'Allocating Bed','In Transfer'];
  const currentStep = 2; // Example: Patient is currently at "Accepted"

  return (
    <div>
      
      {/* Timeline (Step Tracker) */}
      <Stepper activeStep={currentStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
 {/* Patient Info Card */}
 <Card style={{ marginBottom: '20px' }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>Patient Info</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="textSecondary">Name:</Typography>
              <Typography variant="body1">John Doe</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="textSecondary">Date of Birth:</Typography>
              <Typography variant="body1">01/01/1980</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="textSecondary">Status:</Typography>
              <Typography variant="body1">Stable</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="textSecondary">Insurance:</Typography>
              <Typography variant="body1">ABC Health</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="textSecondary">Current Unit:</Typography>
              <Typography variant="body1">ICU</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="textSecondary">Requesting Physician:</Typography>
              <Typography variant="body1">Dr. Jane Smith</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/* Patient Needs Card */}
      <Card style={{ marginBottom: '20px' }}>
        <CardContent>
          <Typography variant="h5">Patient Needs</Typography>
          <Typography>MRI</Typography>
          <Typography>Cardio</Typography>
          <Typography>Other medical procedures as required</Typography>
        </CardContent>
      </Card>

      {/* Accepting Physician Card */}
      <Card style={{ marginBottom: '20px' }}>
        <CardContent>
          <Typography variant="h5">Accepting Physician</Typography>
          <div style={{ marginBottom: '10px' }}>
            <Button variant="contained" style={{ marginRight: '10px' }}>Accept</Button>
            <Button variant="contained"  style={{ marginRight: '10px' }}>Decline</Button>
            <Button variant="contained"  style={{ marginRight: '10px' }}>Reviewing</Button>
            <Button variant="contained" >More Info Required</Button>
          </div>
          <TextField
            label="Physician Notes"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            placeholder="Enter notes here..."
          />
        </CardContent>
      </Card>

    </div>
  );
};

export default TransferReceivePage;
