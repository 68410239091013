
import PatientScannerList from "../PatientScannerList"

import Grid from '@mui/material/Grid';


function ScannerPage() {
  return (
    <div>
      <div class="fixed-bg"></div>
      <Grid  container spacing={2}>
        <Grid item xs={2}>
          
        </Grid>
        <Grid class="patientPage" item xs={10}>
            <h1>Patient Scan Results</h1>
            <PatientScannerList />
        </Grid>
      </Grid>
    </div>
  );
}

export default ScannerPage