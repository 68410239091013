import React, { useState } from 'react';
import { Box, Card, CardContent, Typography, Grid, TextField, Accordion, AccordionSummary, AccordionDetails, Radio, RadioGroup, FormControlLabel, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';


const transportData = [
  {
    "name": "Metro Transit Regional Reduced Fare",
    "type": "Bus, Lightrail",
    "phone": "(206) 553-3000",
    "zips": "King County: 98007, 98008, 98029, 98030, 98038, 98042, 98052, 98056, 98074, 98077, 98101, 98103, 98105, 98115, 98188, 98144, 98148, 98166, 98177, 98201, 98203, 98260",
    "cities": "N/A",
    "accessibility": "N/A",
    "service_hours": "N/A",
    "service_days": "N/A",
    "notes": "This service is for participants age 65 and over, or disabled."
  },
  {
    "name": "Sound Transit",
    "type": "Bus, Lightrail",
    "phone": "N/A",
    "zips": "N/A",
    "cities": "N/A",
    "accessibility": "N/A",
    "service_hours": "N/A",
    "service_days": "N/A",
    "notes": "N/A"
  },
  {
    "name": "Metro ADA Paratransit Program (ACCESS)",
    "type": "Van",
    "phone": "(206) 263-3113 (information)",
    "zips": "N/A",
    "cities": "N/A",
    "accessibility": "N/A",
    "service_hours": "N/A",
    "service_days": "N/A",
    "notes": "(866) 205-5001 (toll-free). This van service follows the same area, days and hours as Metro Transit buses."
  },
  {
    "name": "Volunteer Transportation for Seniors",
    "type": "Car, Van",
    "phone": "(206) 448-5740",
    "zips": "N/A",
    "cities": "N/A",
    "accessibility": "N/A",
    "service_hours": "N/A",
    "service_days": "N/A",
    "notes": "This free service is available for King County residents age 60 and over."
  },
  {
    "name": "Volunteer Chore Services - Catholic Community Services",
    "type": "Car, Van",
    "phone": "(206) 328-5787",
    "zips": "N/A",
    "cities": "N/A",
    "accessibility": "N/A",
    "service_hours": "N/A",
    "service_days": "N/A",
    "notes": "Rides are available throughout King County for medical or other necessary appointments."
  },
  {
    "name": "Taxis",
    "type": "Taxi",
    "phone": "(206) 622-6500",
    "zips": "N/A",
    "cities": "N/A",
    "accessibility": "N/A",
    "service_hours": "N/A",
    "service_days": "N/A",
    "notes": "Wheelchair-accessible taxi services available."
  },
  {
    "name": "King County Metro Taxi Scrip Program",
    "type": "Taxi",
    "phone": "(206) 553-3000",
    "zips": "N/A",
    "cities": "N/A",
    "accessibility": "N/A",
    "service_hours": "N/A",
    "service_days": "N/A",
    "notes": "Save 50% on taxi fares for low-income residents."
  },
  {
    "name": "Paratransit—DSHS Transportation Broker (Snohomish County)",
    "type": "Car, Van",
    "phone": "(855) 766-7433",
    "zips": "N/A",
    "cities": "N/A",
    "accessibility": "N/A",
    "service_hours": "N/A",
    "service_days": "N/A",
    "notes": "Emergent requests handled 24 hours daily."
  },
  {
    "name": "Snoqualmie Valley Transportation (SVT)",
    "type": "N/A",
    "phone": "(425) 888-7001",
    "zips": "N/A",
    "cities": "N/A",
    "accessibility": "N/A",
    "service_hours": "N/A",
    "service_days": "N/A",
    "notes": "Transportation for anyone ages 13+ within the Snoqualmie Valley."
  },
  {
    "name": "Department of Licensing Disabled Parking",
    "type": "N/A",
    "phone": "(360) 902-3770",
    "zips": "N/A",
    "cities": "N/A",
    "accessibility": "N/A",
    "service_hours": "N/A",
    "service_days": "N/A",
    "notes": "Disabled parking permits."
  },
  {
    "name": "AAG Service Group",
    "type": "Cabulance",
    "phone": "(425) 877-8118",
    "zips": "N/A",
    "cities": "N/A",
    "accessibility": "N/A",
    "service_hours": "N/A",
    "service_days": "N/A",
    "notes": "Wheelchair-accessible transportation."
  },
  {
    "name": "Mercy Transportation",
    "type": "Cabulance",
    "phone": "(425) 347-4700",
    "zips": "N/A",
    "cities": "N/A",
    "accessibility": "N/A",
    "service_hours": "N/A",
    "service_days": "N/A",
    "notes": "Wheelchair-accessible transportation."
  },
  {
    "name": "Northwest Ambulance",
    "type": "Cabulance",
    "phone": "(425) 551-8885",
    "zips": "N/A",
    "cities": "N/A",
    "accessibility": "N/A",
    "service_hours": "N/A",
    "service_days": "N/A",
    "notes": "Wheelchair-accessible transportation."
  }
];
const TransportList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredTransports, setFilteredTransports] = useState(transportData);
  const [selectedTransport, setSelectedTransport] = useState(null);
  const navigate = useNavigate();  // React Router's navigation hook

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = transportData.filter(transpo => transpo.name.toLowerCase().includes(value) || transpo.type.toLowerCase().includes(value));
    setFilteredTransports(filtered);
  };

  const handleSelectionChange = (event) => {
    setSelectedTransport(event.target.value);
  };

  const handleSubmit = () => {
    if (selectedTransport) {
      // Navigate to /transfer-list
      alert('New trasfer listing has been created.')
      navigate('/transfer-list');
    } else {
      alert('Please select a transportation resource before submitting.');
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>Transportation Resources</Typography>
      <Box mt={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!selectedTransport}
        >
          Submit
        </Button>
      </Box>
      {/* Search Bar */}
      <Box mt={3} mb={3}>
        <TextField
          fullWidth
          label="Search by Transport Name or Type"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </Box>
      

      {/* Transport Cards with Selection */}
      <RadioGroup value={selectedTransport} onChange={handleSelectionChange}>
        <Grid container spacing={3}>
          {filteredTransports.map((transpo, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card>
                <CardContent>
                  <FormControlLabel
                    value={transpo.name}
                    control={<Radio />}
                    label={<Typography variant="h6">{transpo.name}</Typography>}
                  />
                  <Typography variant="body2" color="textSecondary">Type: {transpo.type}</Typography>
                  <Typography variant="body1" mt={1}>Phone: {transpo.phone}</Typography>

                  {/* Expandable Details */}
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>View More Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="body2">ZIP Codes Covered: {transpo.zips}</Typography>
                      <Typography variant="body2">Cities Covered: {transpo.cities}</Typography>
                      <Typography variant="body2">Accessibility: {transpo.accessibility}</Typography>
                      <Typography variant="body2">Service Hours: {transpo.service_hours}</Typography>
                      <Typography variant="body2">Service Days: {transpo.service_days}</Typography>
                      <Typography variant="body2">Notes: {transpo.notes}</Typography>
                    </AccordionDetails>
                  </Accordion>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </RadioGroup>

      {/* Submit Button */}
      
    </Box>
  );
};

export default TransportList;
