import { useEffect } from "react";
import Gantt from 'frappe-gantt'
const TimelineChart = (props) => {
      
    let tasks = []

    useEffect(() => {
        // eslint-disable-next-line
        tasks = []
    
        var colorClass = ['bar-milestone-red','bar-milestone-yellow','bar-milestone-green']
        for(var i in props.monitors){
            let alertTime = Date.parse(props.monitors[i].alertTime)
            
            const hoursToAdd = 300 * 60 * 60 * 1000;
            alertTime  = new Date(alertTime + hoursToAdd);

            tasks.push({
                id: props.monitors[i].id,
                name: props.monitors[i].name + ": " + props.monitors[i].trigger,
                start: props.monitors[i].started,
                end: alertTime,
                progress: 100, // optional
                custom_class: colorClass[i % 3]
              });
        }
        if(props.monitors.length){
        const gantt = new Gantt("#gantt", tasks, {
            header_height: 50,
            column_width: 80,
            step: 24,
            view_modes: ['Quarter Day', 'Half Day', 'Day', 'Week', 'Month'],
            bar_height: 30,
            bar_corner_radius: 3,
            arrow_curve: 5,
            padding: 18,
            height: 200,
            view_mode: 'Day',
            date_format: 'YYYY-MM-DD',
            custom_popup_html: function(task) {
                // the task object will contain the updated
                // dates and progress value
                return `
                  <div class="details-container">
                    <h5>${task.name}</h5>
                    <p>Expected to finish by</p>
                    <p>${task.progress}% completed!</p>
                  </div>
                `;
              }
        });
        gantt.change_view_mode('Quarter Day')
    }
    // eslint-disable-next-line
    }, [tasks])
    return <svg id="gantt"></svg>
}
export default TimelineChart;