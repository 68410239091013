import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Badge from '@mui/material/Badge';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemButton from '@mui/material/ListItemButton';
import Avatar from '@mui/material/Avatar';
import { orange, blueGrey, red, green } from '@mui/material/colors';
import PersonIcon from '@mui/icons-material/Accessibility';
import WarningIcon from '@mui/icons-material/Warning';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import WorkIcon from '@mui/icons-material/Work';
import CheckIcon from '@mui/icons-material/Check';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MonitorService from './Services/monitor-service';
function buildLoadingTab() {
    return (<ListItem>
        <ListItemButton>

            <ListItemAvatar>
                <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                >
                    <Avatar>
                        <CircularProgress >

                            <WhatshotIcon />
                        </CircularProgress>
                    </Avatar>
                </Badge>
            </ListItemAvatar>
            <ListItemText secondary={
                <div>
                    <h3>Analyzing FHIR Feed...</h3>
                </div>
            } />
        </ ListItemButton>
    </ListItem>
    );
}


export default function FeedList(props) {

    const [monitors, setMonitors] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (alertId) => {
        if (alertId) {
            const monitorService = new MonitorService();
            monitorService.triggerAlert(alertId).then((ms) => {
                ms.json().then((d) => {
                    alert("Alert has been sent.");
                });
            });
        }
        setAnchorEl(null);
    };

    React.useEffect(() => {
        const monitorService = new MonitorService();
        monitorService.getMonitors().then((ms) => {
            ms.json().then((d) => {
                setMonitors(d)
            });
        });
    });

    return (
        <List sx={{ width: '100%', maxWidth: 1200, bgcolor: 'background.paper' }}>
            {props.isLoading ? buildLoadingTab() : ""}
            {monitors.map((item, index) => (
                <ListItem>
                    <ListItemButton>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: red[500] }}>
                                <WarningIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={item.name} secondary={
                            <div>
                                <p className='timestamp'></p>
                                <p className='feed-message'>{item.trigger}<br /><strong>Action: </strong>
                                    {item.action}
                                </p>
                            </div>
                        } />
                    </ ListItemButton>

                    <IconButton id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}>
                        <MoreVertIcon></MoreVertIcon>
                    </IconButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={handleClose}>Resolve</MenuItem>
                        <MenuItem onClick={handleClose}>Transfer</MenuItem>
                        <MenuItem onClick={handleClose}>Escalate</MenuItem>
                        <MenuItem onClick={() => handleClose(item.id)}>Trigger Alert</MenuItem>
                    </Menu>
                </ListItem>
            ))}

            {props.alerts.map((item, index) => (
                <ListItem>

                    <ListItemButton>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: red[500] }}>
                                <WarningIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Inpatient Alert" secondary={
                            <div>
                                <p className='timestamp'></p>
                                <p className='feed-message'>{item}<br /><strong>Action: </strong>
                                    Follow the hospital policy and procedures.
                                </p>
                            </div>
                        } />
                    </ ListItemButton>

                    <IconButton id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}>
                        <MoreVertIcon></MoreVertIcon>
                    </IconButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={handleClose}>Resolve</MenuItem>
                        <MenuItem onClick={handleClose}>Transfer</MenuItem>
                        <MenuItem onClick={handleClose}>Escalate</MenuItem>     
                        <MenuItem onClick={() => handleClose(item.id)}>Trigger Alert</MenuItem>
                    </Menu>
                </ListItem>
            ))}
            <ListItem>

                <ListItemButton>
                    <ListItemAvatar>

                        <Avatar sx={{ bgcolor: red[500] }}>
                            <WarningIcon />
                        </Avatar>
                    </ListItemAvatar>

                    <ListItemText primary="Telemetry Alert - Room A2" secondary={
                        <div>
                            <p className='timestamp'>Triggered: 1 day and 12 hours ago</p>
                            <p className='feed-message'>Criteria are present for discontinuation of telemetry.<br /><strong>Action: </strong>
                                Follow the hospital policy and procedures. Confirm discontinuation of telemetry is appropriate.
                            </p>
                        </div>
                    } />
                </ ListItemButton>

                <IconButton id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}>
                    <MoreVertIcon></MoreVertIcon>
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={handleClose}>Resolve</MenuItem>
                    <MenuItem onClick={handleClose}>Transfer</MenuItem>
                    <MenuItem onClick={handleClose}>Escalate</MenuItem>
                </Menu>
            </ListItem>
            <ListItem>
                <ListItemButton>
                    <ListItemAvatar>
                        <Avatar sx={{ bgcolor: orange[500] }}>
                            <WorkIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Length of Stay Alert - Room A29" secondary={
                        <div>
                            <p className='timestamp'>Triggered: 1 day and 19 hours ago</p>
                            <p className='feed-message'>A discharge order has been placed prior to the patient exceeding 2 midnight/48 hours.  Assessed for Condition Code 44, or the presence of a Medicare recognized exception to the 2 midnight threshold.
                                <br /> <strong>Action: Perform a primary or secondary medical necessity review</strong>
                            </p>
                        </div>
                    } />
                </ListItemButton>

                <IconButton id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}>
                    <MoreVertIcon></MoreVertIcon>
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={handleClose}>Resolve</MenuItem>
                    <MenuItem onClick={handleClose}>Transfer</MenuItem>
                    <MenuItem onClick={handleClose}>Escalate</MenuItem>
                </Menu>
            </ListItem>
            <ListItem>
                <ListItemButton>
                    <ListItemAvatar>

                        <Avatar sx={{ bgcolor: blueGrey[500] }}>
                            <PersonIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="De-escalate Alert - Room C22" secondary={
                        <div>
                            <p className='timestamp'>Triggered: 2 days and 11 hours ago</p>
                            <p className='feed-message'>There are potential orders that do not match the patient's diagnosis, care plan, or status.
                                <br /><strong>Action: </strong> Follow protocol and review the triggering event for potential unnecessary testing or treatment.
                            </p>
                        </div>
                    } />
                </ListItemButton>

                <IconButton id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}>
                    <MoreVertIcon></MoreVertIcon>
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={handleClose}>Resolve</MenuItem>
                    <MenuItem onClick={handleClose}>Transfer</MenuItem>
                    <MenuItem onClick={handleClose}>Escalate</MenuItem>
                </Menu>
            </ListItem>
            <ListItem>
                <ListItemButton>
                    <ListItemAvatar>

                        <Avatar sx={{ bgcolor: green[500] }}>
                            <CheckIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Length of Stay Alert - Room C9" secondary={
                        <div>
                            <p className='timestamp'>Completed</p>
                            <p className='feed-message'>Observation services exceeding defined timeframe.
                                <br /><strong>Action: </strong> Assess for conversion to inpatient status if appropriate.
                            </p>
                        </div>
                    } />
                </ListItemButton>

                <IconButton id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}>
                    <MoreVertIcon></MoreVertIcon>
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={handleClose}>Resolve</MenuItem>
                    <MenuItem onClick={handleClose}>Transfer</MenuItem>
                    <MenuItem onClick={handleClose}>Escalate</MenuItem>
                </Menu>
            </ListItem>
            <ListItem>
                <ListItemButton>
                    <ListItemAvatar>

                        <Avatar sx={{ bgcolor: green[500] }}>
                            <CheckIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Comfort Care Status Alert - Room B13" secondary={
                        <div>
                            <p className='timestamp'>Completed</p>
                            <p className='feed-message'>There are potential orders that do not match the patient's diagnosis, care plan, or status.
                                Patient is comfort care only status.
                                <br />
                                <strong>Action: </strong>Follow protocol and review the triggering event for potential unnecessary testing or treatment.
                            </p>
                        </div>
                    } />
                </ListItemButton>

                <IconButton id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}>
                    <MoreVertIcon></MoreVertIcon>
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={handleClose}>Resolve</MenuItem>
                    <MenuItem onClick={handleClose}>Transfer</MenuItem>
                    <MenuItem onClick={handleClose}>Escalate</MenuItem>
                </Menu>
            </ListItem>
            <ListItem>

                <ListItemButton>
                    <ListItemAvatar>

                        <Avatar sx={{ bgcolor: green[500] }}>
                            <CheckIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Patient Telemetry Alert - Room A31" secondary={
                        <div>
                            <p className='timestamp'>Completed</p>
                            <p className='feed-message'>Patient has been on telemetry longer than the alert threshold.</p>
                        </div>
                    } />
                </ ListItemButton>

                <IconButton id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}>
                    <MoreVertIcon></MoreVertIcon>
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={handleClose}>Resolve</MenuItem>
                    <MenuItem onClick={handleClose}>Transfer</MenuItem>
                    <MenuItem onClick={handleClose}>Escalate</MenuItem>
                </Menu>
            </ListItem>
        </List>
    );
}
